/*
 * @Author       : Banmao
 * @Date         : 2022-12-05 09:27:17
 * @LastEditTime : 2022-12-05 10:18:51
 * @LastEditors  : maculosa
 * @FilePath     : /Z003/src/utils/request.js
 * @Description  :
 */
import axios from 'axios';
import { MessageBox, Message } from 'element-ui';
import store from '@/store';
// import qs from 'qs';

// import config from '../../config/config';
// import { getToken } from '@/utils/auth';
import router from '../router';

const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API,
  // baseURL: config.api,
  baseURL: '/api',
  timeout: 30000
});

service.interceptors.request.use(
  config => {
    // do something before request is sent
    // config.headers['Access-Control-Allow-Origin'] = '*';
    config.headers = {
      // 'Content-Type': 'application/x-www-form-urlencoded'
      // 'Content-type': 'multipart/form-data'
    };
    // config.withCredentials = true;
    if (store.getters.token) {
      config.headers = {
        Authorization: 'Bearer ' + store.getters.token
      };
    }

    // config.method === 'post'
    //   ? (config.data = qs.stringify({ ...config.data }))
    //   : (config.params = { ...config.params });

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

let status = true;
service.interceptors.response.use(
  response => {
    const res = response.data;

    // // 如果状态码 不 为 0, 报错
    // if (res.code === 1) {
    //   Message({
    //     message: res.msg,
    //     type: 'error',
    //     duration: 5 * 1000
    //   });

    //   return Promise.reject(new Error(res.msg || 'Error'));
    // } else
    if (res.code && res.code !== 0 && status) {
      if (res.code === 2 || res.code === 3) {
        status = false;
        // to re-login
        MessageBox.confirm('你已退出登录，再次尝试 ', '确认退出？', {
          confirmButtonText: '重新登录',
          // cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          store.dispatch('user/resetToken');
          router.replace({
            path: 'login',
            query: { redirect: router.currentRoute.fullPath }
          });
        });

        let timeout = setTimeout(() => {
          status = true;
          clearTimeout(timeout);
        }, 1500);
        // return Promise.reject(new Error(res.msg || 'Error'));
      }
    }
    return res;
  },
  error => {
    console.log('err' + error); // for debug
    Message({
      message: error,
      type: 'error',
      duration: 5 * 1000
    });
    return Promise.reject(error);
    // return error;
  }
);

export default service;
