<template>
  <div class="login-wrap">
    <div class="login-panel">
      <div class="login-form">
        <h3 class="login-title">{{ $store.state.app.title }}</h3>
        <el-form
          class="form-box"
          ref="loginForm"
          :model="loginForm"
          :rules="loginRules"
          label-position="top"
          autocomplete="on"
          hide-required-asterisk
        >
          <el-form-item prop="username" label="用户名">
            <el-input
              ref="username"
              v-model="loginForm.username"
              name="username"
              type="text"
              tabindex="1"
              autocomplete="on"
              placeholder="请输入用户名"
            >
              <icon class="login-icon" type="user" slot="prefix" />
            </el-input>
          </el-form-item>
          <el-form-item prop="password" label="密码">
            <!-- <img src="@/assets/login_pass.png" alt="" slot="prefix" /> -->
            <el-input
              :key="passwordType"
              ref="password"
              v-model="loginForm.password"
              :type="passwordType"
              placeholder="请输入密码"
              name="password"
              tabindex="2"
              autocomplete="on"
              @blur="capsTooltip = false"
              @keyup.enter.native="handleLogin"
            >
              <icon class="login-icon" type="lock" slot="prefix" />
              <span class="show-pwd" @click="showPwd" slot="suffix">
                <icon
                  class="login-icon"
                  :type="passwordType === 'password' ? 'eye-hide' : 'eye-view'"
                />
              </span>
            </el-input>
          </el-form-item>
          <el-form-item prop="captcha" class="form-item">
            <div style="display: flex; justify-content: space-between">
              <el-input
                v-model="loginForm.captcha"
                placeholder="验证码"
                style="width: 55%"
                @keyup.enter.native="handleLogin"
              ></el-input>
              <div class="img_aes" @click.prevent="handleCaptcha()">
                <span class="modal"><i class="el-icon-refresh">刷新</i></span>
                <el-image
                  style="line-height: normal"
                  :src="captchaUrl"
                ></el-image>
              </div>
            </div>
          </el-form-item>
          <!-- <el-form-item prop="verify_code" class="form-item">
            <el-input> </el-input>
          </el-form-item> -->

          <el-button
            :loading="loading"
            type="primary"
            style="width: 100%; margin-bottom: 30px"
            @click.native.prevent="handleLogin"
            >登&nbsp;录</el-button
          >
        </el-form>
      </div>
    </div>
    <y-footer />
  </div>
</template>
<script>
import Icon from '@/components/Icon';
import YFooter from '@/components/Footer';
import { captcha } from '@/api/user';
import CryptoAES from '@/utils/crypto_aes';

export default {
  components: {
    Icon,
    YFooter
  },
  data() {
    const validateUsername = (rule, value, callback) => {
      // if (!validUsername(value)) {
      //   callback(new Error('请输入正确的用户名'));
      // } else {
      callback();
      // }
    };
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error('密码不能少于6位'));
      } else {
        callback();
      }
    };
    return {
      captchaUrl: '',
      key: '',
      loginForm: {
        username: '',
        password: '',
        captchaKey: ''
      },
      loginRules: {
        username: [
          { required: true, trigger: 'blur', validator: validateUsername }
        ],
        password: [
          { required: true, trigger: 'blur', validator: validatePassword }
        ]
      },
      passwordType: 'password',
      loading: false
    };
  },
  mounted() {
    if (this.loginForm.username === '') {
      this.$refs.username.focus();
    } else if (this.loginForm.password === '') {
      this.$refs.password.focus();
    }
    this.handleCaptcha();
  },
  methods: {
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
      this.$nextTick(() => {
        this.$refs.password.focus();
      });
    },
    // checkCapsLock() {},
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true;
          const crypto_aes = new CryptoAES(this.key);
          const params = {
            username: crypto_aes.encrypt(this.loginForm.username.trim()),
            password: crypto_aes.encrypt(this.loginForm.password),
            captcha: this.loginForm.captcha,
            captchaKey: this.loginForm.captchaKey
          };

          this.$store
            .dispatch('user/login', params)
            .then(res => {
              if (res.code === 0) {
                this.loading = false;
                this.$message({
                  message: '登录成功！',
                  type: 'success',
                  duration: 5 * 1000
                });
                this.$store.dispatch('app/getMenus');
                this.$store.dispatch('app/setGasData');
                if (res.data.is_site === 1) {
                  this.$router.replace('/ginfo/gas_detail');
                } else {
                  this.$router.replace('/home');
                }
                return true;
              } else {
                this.$message({
                  message: res.msg,
                  type: 'error',
                  duration: 5 * 1000
                });
                this.handleCaptcha();
                // this.$router.replace('/login');
                this.loading = false;
                return false;
              }
            })
            .catch(err => {
              console.log(err);
              this.loading = false;
            });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleCaptcha() {
      captcha().then(res => {
        if (res.code === 0) {
          this.captchaUrl = res.data.captchaUrl;
          this.loginForm.captchaKey = res.data.captchaKey; // 验证码唯一id
          this.key = res.data.key; // 验证码唯一id
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles.scss';
.login {
  &-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 320px;
    width: 100vw;
    height: 100%;
    background: url('../../assets/bg.png') no-repeat 25% center,
      url('../../assets/decoration01.png') no-repeat 90% 95%,
      url('../../assets/decoration02.png') no-repeat 8% 95%,
      url('../../assets/decoration03.png') no-repeat 5% 10%,
      url('../../assets/decoration01.png') no-repeat 70% 8%;
    background-size: auto;
  }

  &-panel {
    position: absolute;
    top: 50%;
    right: 160px;
    transform: translateY(-50%);
    // display: flex;
    // justify-content: center;
    // align-items: center;
    width: 22rem;
    min-height: 24rem;
    overflow: hidden;
    border-radius: 16px;
    background-color: #fff;
    box-shadow: 0px 4px 7px 0px rgba(9, 126, 137, 0.21);
    .banner {
      // padding: 5rem;
      box-sizing: border-box;
      background-color: rgba($--color-primary, 0.4);
      img {
        display: block;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        // width: 483px;
        // height: 542px;
      }
    }
  }
  &-form {
    .form-box {
      width: 100%;
      margin: auto;
      padding: 20px;
      box-sizing: border-box;

      & ::v-deep .el-form-item .el-form-item__label {
        line-height: 0;
      }
    }

    .form-item {
      display: flex;
      width: 100%;
    }
  }

  &-title {
    margin-top: 3rem;
    font-size: 1.5rem;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: $--color-primary;
  }
  &-icon {
    padding-left: 4px;
    font-size: 18px;
    color: $--color-primary;
  }
}

.login-title {
  text-align: center;
}

.login-form,
.banner {
  height: inherit;
  width: 100%;
}
.support {
  position: fixed;
  bottom: 10px;
  text-align: center;
  width: 100%;
  font-size: 14px;
  color: #fff;
}
.img_aes {
  display: flex;
  align-items: Center;
  position: relative;

  .modal {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
    color: #fff;
    text-align: center;
    display: none;
  }
  &:hover {
    cursor: pointer;
    .modal {
      display: block;
    }
  }
}
</style>
