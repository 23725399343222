<!--
 * @Author       : Banmao
 * @Date         : 2022-07-27 15:53:45
 * @LastEditTime : 2022-08-26 10:36:02
 * @LastEditors  : maculosa
 * @FilePath     : /p202/src/views/gas_info_mgr/components/GasForm/index.vue
 * @Description  : 
-->
<template>
  <el-form ref="gasFormRef" :model="form" :rules="rules" label-width="120px">
    <el-row :gutter="20">
      <el-col :span="24">
        <el-form-item label="加油站名称" prop="name">
          <el-input v-model="form.name" placeholder="填写加油站名称" />
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="加油站编号" prop="site_code">
          <el-input v-model="form.site_code" placeholder="填写加油站编号" />
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="社会信用代码" prop="credit_code">
          <el-input v-model="form.credit_code" placeholder="填写社会信用代码" />
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="法人代表" prop="legal">
          <el-input v-model="form.legal" placeholder="填写法人代表" />
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="联系电话" prop="telephone">
          <el-input v-model="form.telephone" placeholder="填写联系电话" />
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="从业人数" prop="people_num">
          <el-input-number
            v-model="form.people_num"
            controls-position="right"
            :min="0"
            placeholder="填写从业人数"
            style="width: 100%; text-align: left"
          />
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="机器数量" prop="machine_num">
          <el-input-number
            v-model="form.machine_num"
            controls-position="right"
            :min="0"
            placeholder="填写机器数量"
            style="width: 100%"
          />
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="登记类型" prop="register_type">
          <el-select
            v-model="form.register_type"
            placeholder="选择登记类型"
            style="width: 100%"
            clearable
          >
            <el-option
              v-for="item in registerTypeList"
              :key="item.name"
              :value="item.name"
              :label="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="开业时间" prop="start_date">
          <el-date-picker
            v-model="form.start_date"
            placeholder="选择开业时间"
            style="width: 100%"
            clearable
          />
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="加油站状态" prop="state">
          <el-select
            v-model="form.state"
            placeholder="选择加油站状态"
            style="width: 100%"
            clearable
          >
            <el-option
              v-for="item in stationStateList"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="区域" prop="area">
          <cascader-area
            ref="cascaderRef"
            @getSelectedOptions="getSelectedOptions"
          />
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="经营地址" prop="address">
          <el-input
            v-model="form.address"
            type="textarea"
            placeholder="填写经营地址"
          />
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <div class="form-footer">
          <el-button type="primary" @click="handleSubmit">保存</el-button>
          <el-button type="default" @click="handleCancel">取消</el-button>
        </div>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import CascaderArea from '@/components/CascaderArea';

import {
  GasAddForm as addStation,
  GasEditForm as saveStation,
  GetDeviceTown as getTownList
} from '@/api/gas_info_mgr';

const rules = {
  name: [{ required: true, message: '请输入加油站名称' }],
  site_code: [
    { required: true, message: '请输入加油站编号' },
    {
      pattern: /^[0-9]{0,20}$/,
      message: '加油站编号不能超过二十位，请重新输入'
    }
  ],
  credit_code: [{ required: true, message: '请输入社会信用代码' }],
  legal: [{ required: true, message: '请输入法人代表' }],
  telephone: [
    { required: true, message: '请输入电话' },
    {
      pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
      message: '格式不正确, 请输入正确的手机格式',
      trigger: 'blur'
    }
  ],
  people_num: [{ required: true, message: '请输入从业人数' }],
  machine_num: [{ required: true, message: '请输入机器数量' }],
  age: [{ required: true, message: '请输入年龄' }],
  depart_id: [{ required: true, message: '请输入监管机构' }],
  town: [{ required: true, message: '请输入管辖街道' }],
  register_type: [{ required: true, message: '请输入登记注册类型' }],
  start_date: [{ required: true, message: '请输入开业时间' }],
  state: [{ required: true, message: '请输入加油站状态' }],
  // lnglats: [{ required: true, message: '请选择所属位置' }],
  area: [{ required: true, message: '选择区域' }],
  address: [{ required: true, message: '请输入地址', trigger: 'blur' }]
};

export default {
  name: 'GasForm',
  components: {
    CascaderArea
  },
  data() {
    return {
      form: {
        area: [],
        people_num: 0,
        machine_num: 0
      },
      rules,
      townList: [],
      stationStateList: [
        { label: '营业', value: '1' },
        { label: '不营业', value: '2' }
      ]
    };
  },
  props: {
    initialValue: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    registerTypeList() {
      return this.$store.state.common.registerTypeList;
    },
    departList() {
      return this.$store.state.common.departList;
    }
  },
  beforeMount() {
    this.form = this.initialValue;
    console.log(this.initialValue);
  },
  created() {
    this.getDepartList();
    this.getRegisterTypeList();
  },
  mounted() {
    const areaArr = this.form.area;

    this.$nextTick(() => {
      if (this.$refs.cascaderRef) {
        this.$refs.cascaderRef.setArea(areaArr);
      }
    });
  },
  watch: {
    initialValue(val) {
      this.form = val;
      const areaArr = this.form.area;

      this.$nextTick(() => {
        if (this.$refs.cascaderRef) {
          this.$refs.cascaderRef.setArea(areaArr);
        }
      });
    }
  },
  methods: {
    getSelectedOptions(val) {
      this.form.area = [...val];
    },
    // 获取注册类型
    getRegisterTypeList() {
      if (this.registerTypeList.length > 0) return;

      this.$store.dispatch('common/getRegisterTypeList');
    },
    // 获取监管机构
    getDepartList() {
      console.log(this.departList);
      if (this.departList.length > 0) return;

      this.$store.dispatch('common/getDepartList');
    },
    getTownList(e) {
      console.log(e);
      getTownList({ depart_id: e });
    },
    handleCancel() {
      this.$store.dispatch('station/toggleVisible', false);
    },
    handleReset() {
      this.$refs.gasFormRef.resetFields();
    },
    handleSubmit() {
      this.$refs.gasFormRef.validate(valid => {
        console.log(valid);
        if (valid) {
          const { area, ...formData } = this.form;
          const params = {
            ...formData,
            province: area[0],
            city: area[1],
            county: area[2],
            town: area[3]
          };
          delete params.lnglats;

          this.form.id ? this.saveStation(params) : this.addStation(params);

          // 获取更新后的搜索栏加油站列表
          this.$store.dispatch('app/setGasData');
        } else {
          console.log('error submit!!!');
          return false;
        }
      });
    },
    addStation(form) {
      addStation(form)
        .then(res => {
          if (res && res.code === 0) {
            this.$message.success(res.msg || '新增成功');

            this.handleReset();
          } else {
            this.$message.success(res.msg || '新增失败');
          }
        })
        .catch(err => {
          throw err;
        });
    },
    saveStation(form) {
      saveStation(form)
        .then(res => {
          if (res && res.code === 0) {
            this.$message.success(res.msg || '更新成功');
            this.handleCancel();
            this.handleReset();
          } else {
            this.$message.error(res.msg || '更新失败，请重试！');
          }
        })
        .catch(err => {
          throw err;
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.form-footer {
  display: flex;
  justify-content: center;
  width: 100%;
}
</style>
