import { getStorage, setStorage, removeStorage } from './storage';

const TokenKey = 'token';

export function getToken() {
  return getStorage(TokenKey);
}

export function setToken(token) {
  setStorage(TokenKey, token);
}

export function removeToken() {
  removeStorage(TokenKey);
}
