import CryptoJS from 'crypto-js';
export class CryptoAES {
  constructor(secretKey, params) {
    this.secretKey = this.parseEncode(secretKey || '!QWE@_890AnY6Z7X');
    this.params = params || {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    };
  }
  parseEncode(s) {
    return CryptoJS.enc.Utf8.parse(s);
  }
  toString(v) {
    return v.toString();
  }
  stringifyEncode(v) {
    return CryptoJS.enc.Utf8.stringify(v);
  }
  encrypt(value) {
    const word = this.parseEncode(value);
    const encrypted = CryptoJS.AES.encrypt(word, this.secretKey, this.params);
    return this.toString(encrypted);
  }
  decrypt(value) {
    const decrypt = CryptoJS.AES.decrypt(value, this.secretKey, this.params);
    return this.toString(this.stringifyEncode(decrypt));
  }
}

/**
 * 将 vue 中监听的对象转化为js 对象
 * @param {object} a
 */
export function toPureObj(a) {
  return JSON.parse(JSON.stringify(a));
}

export function padDate(a) {
  const num = a * 1;
  if (typeof num !== 'number') {
    return false;
  } else {
    if (num >= 0 && num < 10) {
      return '0' + num;
    }
    if (num >= 10) {
      return num;
    }
  }
}

// export function iterableMenu(list, children) {
//   const arr = [];
//   list.map(item => {
//     if (item[children]) {
//       iterableMenu(item[children]);
//     }
//     const name = _.findKey(locales, o => {
//       return 0 === item.name;
//     });
//     arr.push({ name });
//     return arr;
//   });
// }

export function ExportFile(res, title) {
  const blob = new Blob([res], {
    type: 'application/vnd.ms-excel'
  });
  const downloadElement = document.createElement('a');
  const href = window.URL.createObjectURL(blob);
  if (window.navigator.msSaveBlob) {
    //ie浏览器
    try {
      window.navigator.msSaveBlob(blob, title + '.csv');
    } catch (e) {
      console.log(e);
    }
  } else {
    downloadElement.href = href;
    downloadElement.download = `${title}.csv`;
    document.body.appendChild(downloadElement);
    downloadElement.click();
    document.body.removeChild(downloadElement);
    window.URL.revokeObjectURL(href);
  }
}
