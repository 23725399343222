<template>
  <page-header :title="title" v-model="searchParams" :breadcrumbs="pathMatch">
    <template v-slot:searchbar>
      <div style="width: 100%; display: flex; justify-content: space-between;">
        <div>
          <el-button
            type="primary"
            icon="el-icon-plus"
            size="mini"
            @click="handleAdd"
            class="export-btn"
            >新增</el-button
          >
        </div>
        <el-form :inline="true" size="mini">
          <AreaField
            v-model="searchParams.area_code"
            @change="getStationList"
          />
          <el-form-item>
            <el-select
              v-model="searchParams.site_id"
              filterable
              :clearable="true"
              placeholder="加油站（可输入）"
            >
              <el-option
                v-for="item in gasData"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="searchParams.legal"
              clearable
              placeholder="法人代表"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              @click="handleQuery"
              >查询</el-button
            >
            <el-button
              type="primary"
              icon="el-icon-arrow-up"
              size="mini"
              @click="handleExport"
              >导出</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </template>
    <default-table
      :columns="columns"
      :dataSource="data"
      :pagination="pagination"
      @currentChange="handleCurrentChange"
      :loading="tableLoading"
    >
      <template #index="{ scope }">{{ scope.$index + 1 }}</template>
      <template #actions="{ scope }">
        <el-button
          type="primary"
          size="small"
          @click="handleGetRow(scope.row.id)"
        >
          编辑
        </el-button>
        <el-button type="danger" size="small" @click="handleDel(scope.row.id)">
          删除
        </el-button>
      </template>
    </default-table>

    <el-dialog
      :title="dialogAdd ? '新增' : '编辑'"
      :visible.sync="visible"
      width="900px"
      custom-class="dialog"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="handleCancel"
    >
      <GasForm :initialValue="formData" />
    </el-dialog>
  </page-header>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import DefaultTable from '@/components/DefaultTable/new';
import GasForm from './components/GasForm';
import { QueryGases, ExportGases, DelGas, GetGasRow } from '@/api/gas_info_mgr';

import { columns } from './columns/gas';
import mixins from '@/mixins';

export default {
  components: {
    PageHeader,
    DefaultTable,
    GasForm
  },
  data() {
    return {
      tableLoading: true,
      dialogAdd: true,
      searchParams: {
        // area:
        // name: '',
        // legal: ''
      },
      columns,
      data: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10
      },
      formData: {
        area: [],
        people_num: 0,
        machine_num: 0
      },
      lnglats: ''
    };
  },
  mixins: [mixins],
  computed: {
    visible: {
      get() {
        return this.$store.state.station.stationFormVisible;
      },
      set(val) {
        this.$store.commit('station/toggleVisible', val);
      }
    }
  },
  created() {
    this.pathMatch = this.$router.history.current.matched;

    this.handleQuery({
      current: 1,
      pageSize: 10
    });
  },
  mounted() {
    this.getRegisterTypeList();
  },
  methods: {
    // getStationList(area_code) {
    //   this.$store.dispatch('app/setGasData', area_code);
    // },
    // 获取注册类型
    getRegisterTypeList() {
      if (this.$store.state.common.registerTypeList.length > 0) return;

      this.$store.dispatch('common/getRegisterTypeList');
    },
    // 获取监管机构
    handleQuery({ current, pageSize }) {
      this.pagination.current = current || 1;
      const params = {
        page: current || 1,
        limit: pageSize || 10,
        ...this.searchParams
      };
      QueryGases(params).then(res => {
        if (res.code === 0) {
          this.data = res.data;
          this.pagination = {
            ...this.pagination,
            total: res.count
          };
          this.tableLoading = false;
        }
      });
    },
    handleCurrentChange(current) {
      this.handleQuery({
        current,
        pageSize: 10
      });
    },
    handleExport() {
      const params = {
        ...this.searchParams
      };
      ExportGases(params)
        .then(res => {
          this.$ExportFile(res, this.title);
        })
        .catch(err => {
          this.$message({
            message: err,
            type: 'error'
          });
        });
    },
    handleGetRow(id) {
      this.dialogAdd = false;
      GetGasRow({ id }).then(async res => {
        let { code, data } = res;
        if (code == 0) {
          this.formData = data;
          if (data.lng && data.lat) {
            this.formData.lnglats = data.lng + ',' + data.lat;
          } else {
            this.formData.lnglats = '';
          }
          this.formData.state = data.state + '';
          const areaArr = [
            data.province + '',
            data.city + '',
            data.county + '',
            data.town + ''
          ];
          this.formData.area = areaArr;
          this.$store.dispatch('station/toggleVisible', true);
        }
      });
    },
    handleDel(id) {
      this.$confirm('是否删除该加油站？', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          DelGas({ id }).then(res => {
            if (res.code === 0) {
              this.$message.success(res.msg);
              this.handleQuery({
                current: this.pagination.current,
                pageSize: this.pagination.pageSize
              });
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {});
    },
    handleAdd() {
      this.dialogAdd = true;
      this.$store.dispatch('station/toggleVisible', true);
    },
    handleCancel() {
      // this.visible = false;
      this.$store.dispatch('station/toggleVisible', false);

      this.dialogAdd = false;
      this.formData = {
        people_num: 0,
        machine_num: 0
      };

      this.handleQuery({
        current: this.pagination.current,
        pageSize: this.pagination.pageSize
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.innerDialogForm {
  position: absolute;
  top: 60px;
  left: 0;
  padding: 0 20px;
  margin-top: 20px;

  & .el-form-item {
    background: #fff;
    box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 6%),
      6px 0px 8px 0px rgba(0, 0, 0, 5%);
    padding: 10px;
    border-radius: 4px;
  }

  .search {
    line-height: 47px;
    padding: 0 10px;
    font-size: 14px;
  }

  & .el-form-item:last-child {
    background: transparent;
    padding: 0;
    box-sizing: none;
  }
}

#panel {
  position: absolute;
  top: 60px;
  right: 0;
}
</style>
