/*
 * @Author       : Banmao
 * @Date         : 2022-07-28 08:15:05
 * @LastEditTime : 2022-07-28 16:43:05
 * @LastEditors  : maculosa
 * @FilePath     : /p202/src/store/modules/common.js
 * @Description  :
 */
import { getRegisterFactory as getRegisterTypes } from '@/api/global';

import { getDepartDetail as getDepartList } from '@/api/gas_info_mgr';

const state = {
  registerTypeList: [],
  departList: []
};

const mutations = {};

const actions = {
  async getRegisterTypeList({ state }) {
    try {
      const res = await getRegisterTypes({ type: 1 });
      if (res && res.code === 0) {
        state.registerTypeList = res.data;
      }
    } catch (error) {
      throw new Error(error);
    }
  },
  async getDepartList({ state }) {
    try {
      const res = await getDepartList();
      if (res && res.code === 0) {
        state.departList = res.data;
      }
    } catch (error) {
      throw new Error(error);
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
