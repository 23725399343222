import request from '@/utils/request';

// 加油站管理
export function getDepartDetail() {
  return request({
    url: '/index/get_depart_detail',
    method: 'GET'
  });
}

export function QueryGases(data) {
  return request({
    url: '/Device/data',
    method: 'POST',
    data
  });
}
export function ExportGases(data) {
  return request({
    url: '/Device/gas_export.html',
    method: 'POST',
    responseType: 'blob',
    data
  });
}

export function DelGas(data) {
  return request({
    url: '/Device/del.html',
    method: 'POST',
    data
  });
}

export function GetDeviceTown(data) {
  return request({
    url: '/device/get_device_town',
    method: 'POST',
    data
  });
}

export function GasAddForm(data) {
  return request({
    url: '/Device/add_form.html',
    method: 'POST',
    data
  });
}

export function GasEditForm(data) {
  return request({
    url: '/Device/edit_form.html',
    method: 'POST',
    data
  });
}

export function GetGasRow(data) {
  return request({
    url: '/Device/get_gas_detail.html',
    method: 'POST',
    data
  });
}

// 加油机信息
export function QueryMachine(params) {
  return request({
    url: '/Machine/data.html',
    method: 'get',
    params
  });
}

export function ExportMachine(params) {
  return request({
    url: '/Machine/machine_export.html',
    method: 'get',
    responseType: 'blob',
    params
  });
}

export function DelMachine(params) {
  return request({
    url: '/Machine/del.html',
    method: 'POST',
    params
  });
}

export function MachineRow(params) {
  return request({
    url: '/Machine/get_machine_detail.html',
    method: 'POST',
    params
  });
}

export function MachineAddForm(params) {
  return request({
    url: '/Machine/add_form.html',
    method: 'POST',
    params
  });
}

export function MachineEditForm(params) {
  return request({
    url: '/Machine/edit_form.html',
    method: 'POST',
    params
  });
}

// 端口管理
export function QueryTaxPorts(params) {
  return request({
    url: '/Control/data.html',
    method: 'get',
    params
  });
}

export function ExportTaxPorts(params) {
  return request({
    url: '/Control/export',
    method: 'get',
    responseType: 'blob',
    params
  });
}

export function DelTaxPorts(params) {
  return request({
    url: '/Control/del.html',
    method: 'POST',
    params
  });
}

export function TaxPortsAddForm(params) {
  return request({
    url: '/Control/add_form.html',
    method: 'POST',
    params
  });
}

export function TaxPortsEditForm(params) {
  return request({
    url: '/Control/edit_form.html',
    method: 'POST',
    params
  });
}

export function GetTaxPortsRow(params) {
  return request({
    url: '/Control/get_control_detail.html',
    method: 'GET',
    params
  });
}

// 获取加油机列表接口
export function GetMachineList(params) {
  return request({
    url: '/Control/get_machine_list.html',
    method: 'GET',
    params
  });
}

// 获取加油机油枪接口
export function GetMachineRow(params) {
  return request({
    url: '/Control/get_machine_row.html',
    method: 'GET',
    params
  });
}
