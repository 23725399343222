export default {
  login: '登录',
  home: '首页',
  // BasicLayout: '首页',
  gas_info_mgr: '油站信息',
  gas_mgr: '加油站信息',
  gas_detail: '油站详情',
  tank_mangr: '油罐管理',
  mch_mgr: '加油机信息',
  gun_mgr: '加油枪信息',
  stats: '统计查询',
  fuel_record: '加油流水',
  site_detail: '加油机明细',
  site_sum: '加油站汇总',
  oil_price_history: '历史油价',
  oilin: '进油管理',
  oilin_report: '进油上报',
  oilin_record: '进油记录',
  oilin_sum: '进油汇总',
  oil_detection: '上报进油记录',
  nocheat: '防作弊系统状态',
  auto_check: '智能自检',
  gap_lock: '计量超差',
  gap_lock_list: '计量超差锁机状态',
  gap_lock_record: '计量超差记录',
  alarm_record: '告警记录',
  port_offline: '端口离线报警',
  device_off: '设备离线报警',
  m_cpu_change: '监控微处理器变更报警',
  encoder_change: '编码器变更报警',
  tanker_time_diff: '油机时间异常报警',
  factory_diff: '使用厂家防篡改报警',
  import_alr: '备档时间异常报警',
  illegal_fuel_dispenser_alr: '非法加油机报警',
  gas_check_cycle: '油机例行检查',
  // gas_check_list: '例行检查列表',
  gas_check_history: '检查历史',
  gas_check_list: '油机检查记录',
  gas_type_check: '油品抽检',
  params_per: '系统设置',
  params_setted: '参数设置',
  department: '部门权限',
  depart: '分局管理',
  gauge_info: '液位仪信息',
  gauge_mgr: '液位仪信息',
  tank_mgr: '油罐信息',
  level_data: '液位数据',
  gas_level_data: '液位数据',
  level_gauge_abno: '液位仪异常报警',
  high_temp_abno: '油罐高温报警',
  low_oil_abno: '油罐低油位报警',
  oil_tank_abno: '油罐变更报警',
  oilout_abno: '出油异常报警',
  tank_probe_abno: '探针异常报警',
  cheat: '疑似作弊报警',
  measure_alr: '处理器异常报警',
  encoder_alr: '编码器异常报警',
  tank_detail: '油罐详情',
  qrCode: '投诉管理',
  gas_qrCode: '油站二维码',
  complaint_record: '投诉记录',
  Honesty: '油站诚信体系',
  honesty_record: '诚信等级记录',
  honesty_params: '参数设置',
  lead_seal: '录入铅封序列号',
  measure: '录入计量检定',
  'settings.logs': '操作日志'
};
