import GasList from '@/views/gas_info_mgr/Gas';

export default [
  {
    path: '/home',
    name: 'home',
    icon: 'home',
    meta: {
      requireAuth: true
    },
    component: () => import('../views/home/Home.vue')
  },
  {
    path: '/ginfo',
    name: 'gas_info_mgr',
    icon: 'gas_info_mgr',
    component: () => import('@/views/gas_info_mgr'),
    children: [
      {
        name: 'gas_mgr',
        path: '/ginfo/gas',
        component: GasList
      },
      {
        name: 'mch_mgr',
        path: '/ginfo/machine',
        component: () => import('@/views/gas_info_mgr/Machine')
      },
      {
        name: 'gun_mgr',
        path: '/ginfo/tax',
        component: () => import('@/views/gas_info_mgr/Gun')
      },
      {
        name: 'gas_detail',
        path: '/ginfo/gas_detail',
        component: () => import('@/views/gas_info_mgr/GasDetail')
      },
      {
        name: 'tank_mangr',
        path: '/ginfo/tank_mangr',
        component: () => import('@/views/gas_info_mgr/GasTankDetail')
      }
    ]
  },
  {
    path: '/stats',
    name: 'stats',
    icon: 'stats',
    component: () => import('@/views/stats'),
    children: [
      {
        name: 'fuel_record',
        path: '/stats/fuel_record',
        component: () => import('@/views/stats/FuelRecord')
      },
      {
        name: 'site_detail',
        path: '/stats/site_detail',
        component: () => import('@/views/stats/SiteDetail')
      },
      {
        name: 'site_sum',
        path: '/stats/site_sum',
        component: () => import('@/views/stats/SiteSum')
      },
      {
        name: 'oil_price_history',
        path: '/stats/oil_price_history',
        component: () => import('@/views/stats/OilPriceHistory')
      }
    ]
  },
  {
    path: '/oilin',
    name: 'oilin',
    icon: 'oilin',
    component: () => import('@/views/oilin'),
    children: [
      {
        name: 'oilin_report',
        path: '/oilin/oilin_report',
        component: () => import('@/views/oilin/OilinReport')
      },
      {
        name: 'oilin_record',
        path: '/oilin/oilin_record',
        component: () => import('@/views/oilin/OilinRecord'),
        meta: {
          title: '进油记录'
        }
      },
      {
        name: 'oil_detection',
        path: '/oil_detection',
        icon: 'checked',
        component: () => import('@/views/oil_detection/index.vue'),
        meta: {
          label: '上报进油记录'
        }
      },
      {
        name: 'oilin_sum',
        path: '/oilin/oilin_sum',
        component: () => import('@/views/oilin/OilinSum')
      }
    ]
  },
  {
    path: '/qrCode',
    name: 'qrCode',
    icon: 'qrCode',
    component: () => import('@/views/qrCode'),
    children: [
      {
        name: 'gas_qrCode',
        path: '/qrCode/gas_qrCode',
        component: () => import('@/views/qrCode/GasQrCode')
      },
      {
        name: 'complaint_record',
        path: '/qrCode/complaint_record',
        component: () => import('@/views/qrCode/ComplaintRecord')
      }
    ]
  },
  {
    name: 'complaint',
    icon: 'complaint',
    path: '/complaint_record',
    component: () => import('@/views/complaint/index.vue')
  },
  {
    path: '/Honesty',
    name: 'Honesty',
    icon: 'Honesty',
    component: () => import('@/views/Honesty'),
    children: [
      {
        name: 'honesty_record',
        path: '/Honesty/honesty_record',
        component: () => import('@/views/Honesty/honestyRecord')
      },
      {
        name: 'honesty_params',
        path: '/Honesty/honesty_params',
        component: () => import('@/views/Honesty/honestyParams')
      }
    ]
  },
  {
    name: 'gas_level_data',
    path: '/gas_level_data',
    icon: 'gauge_info',
    component: () => import('@/views/gauge_info/gas_level_data')
  },
  {
    path: '/gauge',
    name: 'gauge_info',
    icon: 'gauge_info',
    component: () => import('@/views/gauge_info'),
    children: [
      {
        name: 'gauge_mgr',
        path: '/gauge/level',
        component: () => import('@/views/gauge_info/LevelGauge')
      },
      {
        name: 'tank_mgr',
        path: '/gauge/tank',
        component: () => import('@/views/gauge_info/OilTank')
      },
      {
        name: 'tank_detail',
        path: '/gauge/tank_detail',
        component: () => import('@/views/gauge_info/TankDetail')
      },
      {
        name: 'level_data',
        path: '/gauge/level_data',
        component: () => import('@/views/gauge_info/level_gauge_data')
      }
    ]
  },
  {
    name: 'nocheat',
    path: '/ncheat',
    icon: 'nocheat',
    component: () => import('@/views/no_cheat')
  },
  {
    name: 'auto_check',
    path: '/auto_check',
    icon: 'auto_check',
    component: () => import('@/views/auto_check')
  },
  {
    name: 'gap_lock',
    path: '/gap_lock',
    icon: 'gap_lock',
    component: () => import('@/views/lock'),
    children: [
      {
        name: 'gap_lock_list',
        path: '/gap_lock/list',
        component: () => import('@/views/lock/Lock')
      },
      {
        name: 'gap_lock_record',
        path: '/gap_lock/record',
        component: () => import('@/views/lock/LockRecord')
      }
    ]
  },
  {
    name: 'alarm_record',
    path: '/alr',
    icon: 'alarm',
    component: () => import('@/views/alarms'),
    children: [
      {
        name: 'port_offline',
        icon: 'tax',
        path: '/alr/tax_off',
        component: () => import('@/views/alarms/PortOffline')
      },
      {
        name: 'device_off',
        icon: 'wifi',
        path: '/alr/dvc_off',
        component: () => import('@/views/alarms/DeviceOff')
      },
      {
        name: 'm_cpu_change',
        icon: 'change',
        path: '/alr/tax_chg',
        component: () => import('@/views/alarms/m_cpu_change')
      },
      {
        name: 'encoder_change',
        icon: 'encoder',
        path: '/alr/ecdr_chg',
        component: () => import('@/views/alarms/EncoderChange')
      },
      {
        name: 'tanker_time_diff',
        icon: 'gas',
        path: '/alr/tktd',
        component: () => import('@/views/alarms/DeviceTimeDiff')
      },
      {
        name: 'factory_diff',
        icon: 'gas',
        path: '/alr/fct_diff',
        component: () => import('@/views/alarms/FactoryDiff')
      },
      {
        name: 'import_alr',
        icon: 'gas',
        path: '/alr/import_alr',
        component: () => import('@/views/alarms/ImportAlarm')
      },
      {
        name: 'illegal_fuel_dispenser_alr',
        icon: 'gas',
        path: '/alr/nontax_control',
        component: () => import('@/views/alarms/illegal_fuel_dispenser')
      },

      {
        name: 'level_gauge_abno',
        path: '/alr/level_gauge_abno',
        component: () =>
          import('@/views/alarm_level_gauge/level_gauge_abno.vue')
      },
      {
        name: 'oilout_abno',
        path: '/alr/oilout_abno',
        component: () => import('@/views/alarm_level_gauge/oilout_abno.vue')
      },
      {
        name: 'high_temp_abno',
        path: '/alr/high_temp_abno',
        component: () => import('@/views/alarm_level_gauge/high_temp_abno.vue')
      },
      {
        name: 'low_oil_abno',
        path: '/alr/low_oil_abno',
        component: () => import('@/views/alarm_level_gauge/low_oil_abno.vue')
      },
      {
        name: 'oil_tank_abno',
        path: '/alr/oil_tank_abno',
        component: () => import('@/views/alarm_level_gauge/oil_tank_abno.vue')
      },
      {
        name: 'tank_probe_abno',
        path: '/alr/tank_probe_abno',
        component: () => import('@/views/alarm_level_gauge/tank_probe_abno.vue')
      },
      {
        name: 'cheat',
        path: '/alr/cheat',
        component: () => import('@/views/alarms/cheat.vue')
      },
      {
        name: 'processor_alr',
        path: '/alr/processor_alr',
        component: () => import('@/views/alarm_level_gauge/processor_abno.vue')
      },
      {
        name: 'encoder_alr',
        path: '/alr/encoder_alr',
        component: () => import('@/views/alarm_level_gauge/encoder_abno.vue')
      }
    ]
  },
  {
    name: 'gas_check_cycle',
    icon: 'checked',
    path: '/routine',
    component: () => import('@/layouts/blankLayout.vue'),
    children: [
      {
        name: 'gas_check_list_routine',
        path: '/routine/list',
        component: () => import('@/views/routine/index.vue')
      },
      {
        name: 'gas_check_history',
        path: '/routine/history',
        component: () => import('@/views/routine/HistoryList')
      }
    ]
  },
  {
    name: 'gas_check_list',
    path: '/gas_check',
    icon: 'checked',
    label: '油机检查记录',
    component: () => import('@/views/gas_check/CheckList.vue')
  },
  {
    name: 'lead_seal',
    path: '/lead_seal',
    icon: 'lead_seal_number',
    component: () => import('@/views/lead_seal_number')
  },
  {
    name: 'measure',
    path: '/measure',
    icon: 'measure_verification',
    component: () => import('@/views/measure_verification')
  },
  {
    name: 'gas_type_check',
    path: '/type_check',
    icon: 'checked',
    label: '油品抽检',
    component: () => import('@/views/gas_type_check')
  },
  {
    name: 'params_per',
    icon: 'set',
    path: '/sys',
    component: () => import('@/views/system'),
    children: [
      {
        name: 'params_setted',
        icon: 'params',
        path: '/sys/params',
        component: () => import('@/views/system/ParamsSetting')
      },
      {
        name: 'department',
        icon: 'grp_tree',
        path: '/sys/department',
        component: () => import('@/views/system/Department')
      },
      {
        name: 'depart',
        icon: 'grp_tree',
        path: '/sys/depart',
        component: () => import('@/views/system/depart')
      },
      {
        name: 'settings.logs',
        path: '/sys/logs',
        component: () => import('@/views/system/logs')
      }
    ]
  }
];
