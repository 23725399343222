<template>
  <div class="page-wrap">
    <div class="page-header" v-if="title !== 'none'">
      <div>
        <div class="breadcrumb-box">
          <slot name="breadcrumb" />
          <el-breadcrumb separator="/">
            <template v-for="(item, index) in breadcrumbs">
              <el-breadcrumb-item v-if="index !== 0" :key="index">
                {{ translateToCN(item.name) }}
              </el-breadcrumb-item>
              <el-breadcrumb-item v-else :key="index" :to="item.path || '/'">
                {{ translateToCN(item.name) }}
              </el-breadcrumb-item>
            </template>
          </el-breadcrumb>
        </div>
        <!-- <div class="page-header-title">
          <span>{{ title }}</span>
        </div> -->
      </div>
      <div v-if="$slots.searchbar" class="table-searchbar">
        <slot name="searchbar" />
      </div>
    </div>
    <div class="page-main">
      <slot></slot>
    </div>
  </div>
</template>

<script>
// import classnames from 'classnames';

export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    breadcrumbs: {
      type: Array
    },
    page: {
      type: Object,
      default: () => {
        return {
          total: 0
        };
      }
    }
  },
  methods: {
    translateToCN(val) {
      return this.$locales[val];
    }
  }
};
</script>

<style lang="scss">
@import '@/styles.scss';
.page {
  &-wrap {
    margin: 16px;
    margin-top: 0;
    padding: 20px 16px;
    min-height: calc(100% - 32px);
    box-sizing: border-box;
    background-color: #ffffff;
  }
  &-header {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    // padding-bottom: 14px;
    // height: 86px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(243, 244, 244, 1);
    &-title {
      margin-top: 18px;
      font-size: 20px;
      color: $--color-primary;
      line-height: 24px;
    }
  }
  .breadcrumb-box {
    padding: 0;
  }
}

.table-searchbar {
  display: flex;
  // margin: 23px 0;
  margin: 10px 0 0;
  justify-content: flex-end;
}

.pagination {
  margin-top: 39px;
  text-align: center;
}
</style>
