<template>
  <el-menu
    :class="menuClasses"
    :collapse="collapse"
    :unique-opened="true"
    :collapse-transition="false"
    :default-active="defaultMenu"
  >
    <template v-for="(menu, i) in dataSource">
      <el-submenu
        v-if="!!menu.children"
        :index="menu.path"
        :key="i"
        @click="handlePush(menu.path)"
      >
        <template slot="title">
          <Icon :type="menu.icon" />
          <span class="icon-gutter">{{ menu.label }}</span>
        </template>
        <template v-for="(submenu, j) in menu.children">
          <el-menu-item
            :index="submenu.path"
            :key="j"
            @click="handlePush(submenu.path)"
            >{{ submenu.label }}</el-menu-item
          >
        </template>
      </el-submenu>
      <el-menu-item
        v-else
        :index="menu.path"
        :key="i"
        @click="handlePush(menu.path)"
      >
        <Icon :type="menu.icon" />
        <span slot="title" class="icon-gutter">{{ menu.label }}</span>
      </el-menu-item>
    </template>
  </el-menu>
</template>

<script>
import Icon from '@/components/Icon';
import classnames from 'classnames';

export default {
  components: { Icon },
  props: {
    dataSource: Array,
    collapse: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      current: ''
    };
  },
  computed: {
    defaultMenu() {
      // const menu = localStorage.getItem('currentPath');
      // this.$router.history.current.path;
      const menu =
        this.$store.state.app.defaultMenu || this.$router.history.current.path;
      // console.log(this.$store.state.app.defaultMenu);
      return menu;
    },
    menuClasses() {
      return classnames('zx-menu', {
        dark: this.theme === 'dark'
      });
    }
  },
  methods: {
    handlePush(data) {
      if (data === this.$router.history.current.path) {
        return false;
      }
      // this.$store.commit('SET_DEFAULTBAR', data);

      // localStorage.setItem('currentPath', data);
      this.$store.dispatch('app/setCurrentMenu', data).then(() => {
        this.$router.push(data);
      });
      // this.$router.push(data);
    }
  }
};
</script>
<style lang="scss">
@import '@/styles.scss';

@mixin active($opacity: 1, $textColor: #fff) {
  color: $textColor;
  background: rgba($--color-primary, $opacity);
}

.zx-menu {
  padding-top: 10px;
  &.el-menu {
    border-right: 0;
  }
  &.dark {
    // background-color: $dark-color;
    background: transparent;
    color: #ffffff;
    &.el-menu {
      border-right: 0;
    }
    .el-menu {
      background-color: transparent;
      color: #ffffff;
    }
  }

  .el-menu-item:focus,
  .el-menu-item.is-active {
    position: relative;
    @include active(0.3, #333);
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 3px;
      background-color: $--color-primary;
    }
  }

  .el-menu-item:hover,
  .el-submenu__title:hover {
    @include active(0.6, #333);
    i {
      color: inherit;
    }
  }

  .el-menu-item,
  .el-submenu__title,
  .el-submenu .el-menu-item {
    height: 52px;
    line-height: 52px;
    // color: #ffffff;
  }
}

i + .icon-gutter {
  margin-left: 8px;
}

.el-submenu.is-active .el-submenu__title {
  color: $--color-primary;
  transition: all 0.3s ease-out;
}
.el-submenu.is-active .el-submenu__title:hover {
  color: #333;
}
</style>
