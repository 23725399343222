import { login, logout, captcha } from '@/api/user';
import { getToken, setToken, removeToken } from '@/utils/auth';
import { setStorage, getStorage, clearStorage } from '@/utils/storage';

const state = {
  token: getToken(),
  userInfo: getStorage('userInfo'),
  site_id: getStorage('site_id'),
  is_site: getStorage('is_site'),
  introduction: '',

  alarmCount: getStorage('alarmCount'),
  alarmPort: getStorage('alarmPort'),
  alarmControl: getStorage('alarmControl'),
  alarmDuty: getStorage('alarmDuty'),
  alarmStatus: getStorage('alarmStatus'),
  ifIgnore: getStorage('ifIgnore')
};

const mutations = {
  SET_TOKEN: (state, token) => {
    setToken(token);
    state.token = token;
  },
  SET_USERINFO: (state, userInfo) => {
    setStorage('userInfo', userInfo);
    setStorage('level', userInfo.level || null);
    state.userInfo = userInfo;
  },
  SET_SITE_ID: (state, data) => {
    state.site_id = data;
  },
  SET_IS_SITE: (state, data) => {
    state.is_site = data;
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction;
  },
  SET_ALARMCOUNT: (
    state,
    { alarm_count, alarm_port, alarm_control, alarm_duty, alarm_status }
  ) => {
    setStorage('alarmCount', alarm_count);
    setStorage('alarmPort', alarm_port);
    setStorage('alarmControl', alarm_control);
    setStorage('alarmDuty', alarm_duty);
    setStorage('alarmStatus', alarm_status);
    setStorage('ifIgnore', 'true');
    state.alarmCount = alarm_count;
    state.alarmPort = alarm_port;
    state.alarmControl = alarm_control;
    state.alarmDuty = alarm_duty;
    state.alarmStatus = alarm_status;
    state.ifIgnore = 'true';
  }
};

const actions = {
  // user login
  login({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      login(userInfo)
        .then(response => {
          const { data } = response;
          // console.log(response);
          // commit('SET_TOKEN', data.token);
          const { token, site_id, alarm, ...userInfo } = data;
          try {
            // is_site等于1是加油站账号，0是监管端账号
            if (data.is_site === 1) {
              commit('SET_SITE_ID', site_id);
              setStorage('site_id', site_id);
            }
            commit('SET_IS_SITE', data.is_site);
            setStorage('is_site', data.is_site);
            commit('SET_TOKEN', token);
            commit('SET_LOCATION', { lnt: data.lnt, lat: data.lat });
            commit('SET_USERINFO', userInfo);

            const {
              alarm_count = 0,
              alarm_port = 0,
              alarm_control = 0,
              alarm_duty = 0,
              alarm_status = 0
            } = alarm;
            commit('SET_ALARMCOUNT', {
              alarm_count: alarm_count,
              alarm_port: alarm_port,
              alarm_control: alarm_control,
              alarm_duty: alarm_duty,
              alarm_status: alarm_status
            });
          } catch (error) {
            console.log(error);
          }
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  // user logout
  logout({ commit, state }) {
    const { user_id } = state;
    return new Promise((resolve, reject) => {
      logout({ user_id })
        .then(res => {
          commit('SET_TOKEN', '');
          clearStorage();
          // dispatch('tagsView/delAllViews', null, { root: true });

          resolve(res);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '');
      commit('SET_ROLES', []);
      removeToken();
      resolve();
    });
  },

  captcha() {
    return new Promise((resolve, reject) => {
      captcha()
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
