import CryptoJS from 'crypto-js';
export class CryptoAES {
  constructor(secretKey, params) {
    this.secretKey = this.parseEncode(secretKey || '!QWE@_890AnY6Z7X');
    this.params = params || {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    };
  }
  parseEncode(s) {
    return CryptoJS.enc.Utf8.parse(s);
  }
  toString(v) {
    return v.toString();
  }
  stringifyEncode(v) {
    return CryptoJS.enc.Utf8.stringify(v);
  }
  enCode(v) {
    return encodeURIComponent(v);
  }
  encrypt(value) {
    const word = this.parseEncode(value);
    const encrypted = CryptoJS.AES.encrypt(word, this.secretKey, this.params);
    return this.enCode(this.toString(encrypted));
  }
  decrypt(value) {
    const decrypt = CryptoJS.AES.decrypt(value, this.secretKey, this.params);
    return this.enCode(this.toString(this.stringifyEncode(decrypt)));
  }
}

const CryptoSecret = '__CryptoJS_Secret__';

/**
 * 加密数据
 * @param data - 数据
 */
export function encrypto(data) {
  const newData = JSON.stringify(data);
  return CryptoJS.AES.encrypt(newData, CryptoSecret).toString();
}

/**
 * 解密数据
 * @param cipherText - 密文
 */
export function decrypto(cipherText) {
  const bytes = CryptoJS.AES.decrypt(cipherText, CryptoSecret);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  if (originalText) {
    return JSON.parse(originalText);
  }
  return null;
}

export default CryptoAES;
