import Cookies from 'js-cookie';
import _ from 'lodash';
import config from '../../../config/config';
import { getGasData, getSiderMenus } from '@/api/global';
// import locales from '@/locales/zh_CN';
import { Message } from 'element-ui';
import store from '@/store';
import router from '@/router';

// import responseData from '@/server/remote_menus';

const state = {
  siderbar: {
    opend: Cookies.get('sidebarStatus')
      ? !!+Cookies.get('sidebarStatus')
      : true,
    withoutAnimation: false
  },
  title: config.title,
  menus: [],
  defaultMenu: '',
  gasData: [],
  rules: []
};

const mutations = {
  TOGGLE_SIDEBAR: state => {
    state.sidebar.opened = !state.sidebar.opened;
    state.sidebar.withoutAnimation = false;
    if (state.sidebar.opened) {
      Cookies.set('sidebarStatus', 1);
    } else {
      Cookies.set('sidebarStatus', 0);
    }
  },
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    Cookies.set('sidebarStatus', 0);
    state.sidebar.opened = false;
    state.sidebar.withoutAnimation = withoutAnimation;
  },
  SET_DEFAULTBAR: (state, data) => {
    // console.log(data);
    state.defaultMenu = data;
  },
  SET_RULES: (state, data) => {
    state.rules = data;
  },
  SET_GAS_DATA: (state, data) => {
    state.gasData = data;
  },
  SET_SIDER_MENU: (state, data) => {
    state.menus = data;
  }
};

const actions = {
  toggleSideBar({ commit }) {
    commit('TOGGLE_SIDEBAR');
  },
  closeSideBar({ commit }, { withoutAnimation }) {
    commit('CLOSE_SIDEBAR', withoutAnimation);
  },
  setCurrentMenu({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit('SET_DEFAULTBAR', payload);
        localStorage.setItem('currentPath', payload);
        resolve();
      } catch (err) {
        reject(err);
      }
    });
  },
  setGasData({ commit }, area_code) {
    return new Promise(() => {
      getGasData({ area_code }).then(response => {
        const { code, data } = response;

        if (code === 0) {
          commit('SET_GAS_DATA', data);
        } else {
          console.error(response.msg);
        }
      });
    });
  },
  // 路由权限匹配
  getMenus({ commit }) {
    function iterableMenu(data) {
      let menuData = [];
      data.map(item => {
        let children = [];
        // console.log(_.isEmpty(item.tree));
        const name = item.name_en;
        if (!_.isEmpty(item.tree)) {
          children = iterableMenu(item.tree);

          menuData.push({
            name,
            label: item.name,
            path: item.url,
            icon: name,
            children
          });
        } else {
          menuData.push({
            name,
            label: item.name,
            path: item.url,
            icon: name
          });
        }
      });
      return menuData;
    }
    return new Promise((resolve, reject) => {
      getSiderMenus()
        .then(response => {
          const { code, data } = response;
          if (!data) {
            Message({
              title: '警告',
              message: '获取不到当前帐号下的菜单栏权限数据，将退出当前帐号',
              showClose: false,
              duration: 3000
            });
            setTimeout(() => {
              store.dispatch('user/resetToken').then(() => {
                location.reload();
              });
              router.replace('/login');
            }, 3000);

            return false;
          }
          const resData = _.sortBy(data.rulelist, 'weight');
          commit('SET_RULES', resData);
          // console.log(data.rulelist);
          // commit('SET_RULES', resData);
          if (code === 0) {
            const menuData = iterableMenu(resData);

            // console.log(menuData);

            commit('SET_SIDER_MENU', menuData);
            resolve(menuData);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
