import request from '@/utils/request';
// import qs from 'qs';

export function login(data) {
  return request({
    url: '/login/login',
    method: 'post',
    data
    // data: qs.stringify(data)
  });
}

export function getInfo(token) {
  return request({
    url: '/getUserInfo',
    method: 'get',
    params: { token }
  });
}

export function logout(data) {
  return request({
    url: '/login/logout',
    method: 'get',
    data
  });
}

export function captcha(data) {
  return request({
    url: '/login/captcha',
    method: 'get',
    data
  });
}
