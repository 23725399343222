import Vue from 'vue';
import VueRouter from 'vue-router';
import menusRouter from './menus';
import BasicLayout from '../layouts/basicLayout';
// import BlankLayout from '../layouts/blankLayout';
import Login from '@/views/user/Login';

Vue.use(VueRouter);

const routes = [
  {
    redirect: '/home',
    path: '/'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/datav',
    component: () => import('@/views/DataV/index.vue')
  },
  {
    path: '/PhoneComplaint',
    component: () => import('@/views/qrCode/PhoneComplaint.vue')
  },
  {
    path: '/',
    name: 'app',
    component: BasicLayout,
    children: menusRouter
  },
  {
    path: '*',
    component: () => import('../views/NotFoundComponent')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
