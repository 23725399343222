<template>
  <div>
    <div class="logo" v-show="!mini">
      <img v-if="!!src" class="logo-img" :src="src" :alt="title" />
      <span class="logo-text">燃油加油机智慧监管服务平台</span>
      <!-- <span class="logo-text">{{ title }}</span> -->
    </div>
    <div class="logo-mini" v-show="mini">
      <img v-if="!!src" class="logo-img-mini" :src="src" alt="" />
    </div>
  </div>
</template>
<script>
import classnames from 'classnames';

export default {
  props: {
    title: {
      type: String,
      value: 'logo'
    },
    src: {
      type: String,
      value: null
    },
    theme: {
      type: String,
      value: ''
    },
    mini: {
      type: Boolean,
      value: false
    }
  },
  computed: {
    className() {
      return classnames('logo', this.theme);
    }
  }
};
</script>
