<!--
 * @Author       : Banmao
 * @Date         : 2022-07-26 14:48:35
 * @LastEditTime : 2022-08-24 11:28:50
 * @LastEditors  : maculosa
 * @FilePath     : /p202/src/components/CascaderArea/index.vue
 * @Description  : 
-->
<template>
  <el-cascader
    v-if="isShowInfo"
    :props="props"
    v-model="selectedValue"
    placeholder="选择区域"
    @change="handleChange"
    clearable
    style="width: 100%"
  ></el-cascader>
</template>

<script>
import { getAreaList } from '@/api/global';

export default {
  name: 'cascaderArea',
  data() {
    return {
      isShowInfo: true,
      selectedValue: [],
      props: {
        lazy: true,
        lazyLoad: (node, resolve) => {
          const { level } = node;
          const nodes = [];
          let code = level === 0 ? '0' : node.value;

          this.getAreaList(code)
            .then(res => {
              res.data.map(item => {
                let area = {
                  value: item.code,
                  label: item.name,
                  leaf: level >= 3
                };
                nodes.push(area);
              });
              resolve(nodes);
            })
            .catch(err => console.log(err));
        }
      }
    };
  },
  methods: {
    // 控制回显
    setArea(arr) {
      this.selectedValue = [];
      this.isShowInfo = false;
      if (arr) {
        this.selectedValue = [...arr];
      }
      this.$nextTick(() => {
        this.isShowInfo = true;
      });
    },
    getAreaList(code) {
      return getAreaList({ code });
    },
    handleChange(v) {
      this.$emit('getSelectedOptions', v);
    }
  }
};
</script>
