export default {
  computed: {
    gasData() {
      return this.$store.getters.gasData;
    },
    title() {
      const currentName = this.$router.history.current.name;
      return this.$locales[currentName];
    }
  },
  mounted() {
    if (this.gasData && this.gasData.length === 0) {
      this.$store.dispatch('app/setGasData');
    }
  },
  methods: {
    getStationList(area_code) {
      this.$store.dispatch('app/setGasData', area_code);
    }
  }
};
