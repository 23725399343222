import router from './router';
import store from './store';
import { Message } from 'element-ui';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { getToken } from '@/utils/auth';

// let asyncRouterFlag = 0;

NProgress.configure({ showSpinner: false });

router.beforeEach((to, from, next) => {
  const hasToken = store.getters.token || getToken();
  NProgress.start();
  console.log('path', to.path);
  if (to.path === '/PhoneComplaint') {
    next();
    NProgress.done();
  } else if (!hasToken && to.path !== '/login') {
    next({
      path: '/login'
      // query: { redirect: to.fullPath }
    });
    if (getToken()) {
      Message({
        message: '登录失效',
        type: 'error'
      });
    }
    NProgress.done();
  } else if (hasToken) {
    // store.dispatch('app/getMenus');
    next();
    NProgress.done();
  } else {
    // store.dispatch('app/getMenus');
    next();
    NProgress.done();
  }
});

router.afterEach(() => {
  // finish progress bar
  NProgress.done();
});
