export const columns = [
  {
    title: '序号',
    key: 'index',
    width: 64,
    align: 'center',
    fixed: true,
    scopedSlots: {
      customRender: 'index'
    }
  },
  {
    title: '加油站名称',
    key: 'name',
    fixed: true,
    minWidth: 210
  },
  {
    title: '社会信用代码',
    key: 'credit_code',
    minWidth: 180
  },
  {
    title: '法人 代表',
    key: 'legal',
    width: 100
  },
  {
    title: '联系电话',
    key: 'telephone',
    width: 140
  },
  {
    title: '加油机数',
    key: 'machine_num',
    align: 'center',
    width: 80
  },
  // {
  //   title: '油罐数量',
  //   key: 'tank_num',
  //   width: 80
  // },
  {
    title: '端口数量',
    key: 'tax_port_num',
    align: 'center',
    width: 80
  },
  // {
  //   title: '经营地址',
  //   key: 'address',
  //   minWidth: 150
  // },
  {
    title: '日期',
    // fixed: 'right',
    align: 'center',
    width: 120,
    key: 'start_date'
  },
  {
    title: '操作',
    fixed: 'right',
    key: 'actions',
    width: 150,
    // extra: [{ label: '详情', name: 'edit' }]
    scopedSlots: {
      customRender: 'actions'
    }
  }
];

export const formDesc = {
  name: {
    type: 'input',
    layout: 24,
    label: '加油站名称'
  },
  site_code: {
    type: 'input',
    layout: 24,
    label: '加油站编号'
  },
  credit_code: {
    type: 'input',
    layout: 12,
    label: '社会信用代码'
  },
  legal: {
    type: 'input',
    layout: 12,
    label: '法人代表'
  },
  telephone: {
    type: 'input',
    layout: 12,
    label: '电话'
  },
  people_num: {
    type: 'number',
    layout: 12,
    label: '从业人数',
    style: {
      width: '100%'
    }
  },
  machine_num: {
    type: 'number',
    layout: 12,
    label: '机器数量',
    style: {
      width: '100%'
    }
  },
  depart_id: {
    type: 'select',
    label: '监管机构',
    layout: 12
  },
  town: {
    type: 'select',
    label: '管辖街道',
    layout: 12
  }
};

export const formDescTwo = {
  start_date: {
    type: 'date',
    layout: 12,
    label: '开业时间',
    attrs: {
      'value-format': 'yyyy-MM-dd'
    }
  },
  state: {
    type: 'select',
    layout: 12,
    label: '加油站状态',
    options: [
      { text: '营业', value: '1' },
      { text: '不营业', value: '2' }
    ]
  }
};
