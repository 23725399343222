<!--
 * @Author       : Banmao
 * @Date         : 2022-10-11 15:19:03
 * @LastEditTime : 2022-12-02 10:20:45
 * @LastEditors  : maculosa
 * @FilePath     : /P002/src/components/Searchbar/AreaField.vue
 * @Description  : 
-->
<template>
  <el-form-item :label="label">
    <el-cascader
      :value="value"
      clearable
      :placeholder="placeholder"
      :props="props"
      popper-class="no-radio"
      ref="cascaderRef"
      @change="handleChange"
    >
    </el-cascader>
  </el-form-item>
</template>
<script>
import { getAreaList } from '@/api/global';

/**
 * 判断是否为乡镇
 * @return boolean
 */
function isTown(area) {
  if (!/0{3}$/g.test(area)) {
    return true;
  }
  return false;
}

export default {
  name: 'AreaField',
  props: {
    value: { type: String },
    label: { type: String, default: '' },
    placeholder: { type: String, default: '选择区域' }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  data() {
    return {
      props: {
        expandTrigger: 'hover',
        emitPath: false, // 返回选中节点的值，而非数组
        checkStrictly: true, // 父子节点不相互关联
        lazy: true,
        lazyLoad: (node, resolve) => {
          let areaCode = this.$store.getters.areaCode;
          const { value } = node;
          // const nodes = [];
          if (value) {
            areaCode = value;
          }
          if (!/0{3}$/g.test(areaCode)) {
            resolve();
          }

          getAreaList({ code: areaCode }).then(res => {
            if (res && res.code === 0) {
              // const isTownCode = /0{5}$/g.test(areaCode);

              const nodes = res.data.map(item => {
                return {
                  value: item.code,
                  label: item.name,
                  leaf: isTown(item.code)
                };
                // nodes.push(area);
              });
              resolve(nodes);
            }
          });
        }
      }
    };
  },
  methods: {
    handleChange(val) {
      this.$emit('input', val);
      this.$emit('change', val);
      if (this.$refs.cascaderRef) {
        this.$refs.cascaderRef.dropDownVisible = false;
      }
    }
  }
};
</script>
<style lang="scss">
.no-radio {
  .el-radio__inner {
    position: absolute;
    top: -18px;
    left: -19px;
    border-radius: 0;
    border: 0;
    width: 170px;
    height: 34px;
    background-color: transparent;
    cursor: pointer;
    box-sizing: border-box;
  }

  .el-radio__input.is-checked .el-radio__inner {
    background-color: transparent;
  }
}
</style>
