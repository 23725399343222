/*
 * @Author       : Banmao
 * @Date         : 2022-12-05 16:37:23
 * @LastEditTime : 2022-12-06 09:29:49
 * @LastEditors  : maculosa
 * @FilePath     : /Z003/src/main.js
 * @Description  :
 */
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import './styles.scss';
import EleForm from 'vue-ele-form';
import dataV from '@jiaminghi/data-view';
import CityCascader from './components/CityCascader';
import AreaField from './components/SearchBar/AreaField.vue';
import VueAMap from 'vue-amap';

import './permission';
// import './utils/error-log';

import locales from './locales/zh_CN';
import { ExportFile } from './utils/utils';
import moment from 'moment';

// 二维码
import vueQr from 'vue-qr';

Vue.prototype.$moment = moment;

moment.locale('zh-cn');

Vue.prototype.$locales = locales;
Vue.prototype.$ExportFile = ExportFile;

Vue.component('CityCascader', CityCascader);
Vue.component('AreaField', AreaField);

VueAMap.initAMapApiLoader({
  key: 'c8b392784cc9884e71348be494acd83c',
  plugin: [
    'MarkerClusterer',
    'Geocoder',
    'PlaceSearch',
    'Autocomplete',
    'AMap.DistrictExplorer'
  ],
  v: '1.4.4',
  uiVersion: '1.1'
});

Vue.use(dataV);
Vue.use(ElementUI);
// Vue.use(VueCompositionAPI);
// 注册 vue-ele-form
Vue.use(EleForm);
Vue.use(vueQr);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
