/*
 * @Author       : Banmao
 * @Date         : 2022-05-28 08:51:36
 * @LastEditTime : 2022-07-23 08:47:17
 * @LastEditors  : maculosa
 * @FilePath     : /p202/src/api/global.js
 * @Description  :
 */
import request from '@/utils/request';

export function getAreaList(data) {
  return request({
    url: '/index/get_linkage_region',
    method: 'POST',
    data
  });
}

export function getGasData(data) {
  return request({
    url: '/index/get_site',
    method: 'POST',
    data
  });
}

export function getSiderMenus(params) {
  return request({
    url: '/index/index_rule',
    method: 'get',
    params
  });
}

export function getRegisterFactory(params) {
  return request({
    url: '/index/get_register_factory',
    method: 'get',
    params
  });
}

export function getDepartList() {
  return request({
    url: '/index/get_depart',
    method: 'GET'
  });
}

export function getTanker(params) {
  return request({
    url: '/oil_tank/get_tanker',
    method: 'get',
    params
  });
}

export function getPortGun(params) {
  return request({
    url: '/oil_tank/get_port_gun',
    method: 'get',
    params
  });
}

export function tankDelete(params) {
  return request({
    url: '/oil_tank/del',
    method: 'get',
    params
  });
}
