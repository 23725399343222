<!--
 * @Author       : Banmao
 * @Date         : 2022-08-22 15:26:32
 * @LastEditTime : 2022-08-23 10:36:48
 * @LastEditors  : maculosa
 * @FilePath     : /p201/src/components/CityCascader/index.vue
 * @Description  : 
-->
<template>
  <el-cascader
    :value="value"
    clearable
    :placeholder="placeholder"
    :props="props"
    popper-class="no-radio"
    ref="cascaderRef"
    @change="handleChange"
  >
  </el-cascader>
</template>
<script>
import { getAreaList } from '@/api/global';

export default {
  name: 'CityCascader',
  data() {
    return {
      props: {
        expandTrigger: 'hover',
        emitPath: false, // 返回选中节点的值，而非数组
        checkStrictly: true, // 父子节点不相互关联
        lazy: true,
        lazyLoad: (node, resolve) => {
          let areaCode = this.$store.getters.areaCode || 0;
          const { value } = node;
          // const nodes = [];
          if (value) {
            areaCode = value;
          }
          if (!/0{3}$/g.test(areaCode)) {
            resolve();
          }

          function isTown(area) {
            if (!/0{3}$/g.test(area)) {
              return true;
            }
            return false;
          }

          getAreaList({ code: areaCode }).then(res => {
            if (res && res.code === 0) {
              // const isTownCode = /0{5}$/g.test(areaCode);

              const nodes = res.data.map(item => {
                return {
                  value: item.code,
                  label: item.name,
                  leaf: isTown(item.code)
                };
                // nodes.push(area);
              });
              resolve(nodes);
            }
          });
        }
      }
    };
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: String
    },
    placeholder: {
      type: String,
      default: '选择区域'
    }
  },
  methods: {
    handleChange(val) {
      console.log(val);
      this.$emit('change', val);

      if (this.$refs.cascaderRef) {
        this.$refs.cascaderRef.dropDownVisible = false;
      }
    }
  }
};
</script>
<style lang="scss">
.no-radio {
  .el-radio__inner {
    position: absolute;
    top: -18px;
    left: -19px;
    border-radius: 0;
    border: 0;
    width: 170px;
    height: 34px;
    background-color: transparent;
    cursor: pointer;
    box-sizing: border-box;
  }

  .el-radio__input.is-checked .el-radio__inner {
    background: transparent;
  }
}
</style>
