<template>
  <i :class="className" :style="{ 'font-size': size }" />
</template>

<script>
import classnames from 'classnames';

export default {
  props: {
    type: {
      type: String,
      value: null
    },
    size: {
      type: String,
      value: '16px'
    }
  },
  computed: {
    className() {
      return classnames('iconfont', {
        [`icon-${this.type}`]: !!this.type
      });
    }
  }
};
</script>
<style lang="scss" scoped>
// @import 'http://at.alicdn.com/t/font_1834651_2plzqncur32.css';
@import '../../assets/font_1834651_0ynwyvepkvj/iconfont.css';
.iconfont {
  font-size: 20px;
  // font-weight: bold;
  // color: #000;
}
</style>
