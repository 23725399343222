const getters = {
  sidebar: state => state.app.sidebar,
  token: state => state.user.token,
  avatar: state => state.user.userInfo.avatar,
  name: state => state.user.userInfo.name,
  roles: state => state.user.userInfo.roles,
  errorLogs: state => state.errorLog.logs,
  gasData: state => state.app.gasData,
  area: state => state.user.area,
  alarmCount: state => state.user.alarmCount,
  alarmPort: state => state.user.alarmPort,
  alarmControl: state => state.user.alarmControl,
  alarmDuty: state => state.user.alarmDuty,
  alarmStatus: state => state.user.alarmStatus,
  areaCode(state) {
    return state.user.userInfo.code;
  },
  departName(state) {
    return state.user.userInfo.depart_name;
  },
  username(state) {
    return state.user.userInfo.username;
  }
};

export default getters;
