<template>
  <el-container>
    <el-header height="70px">
      <logo :src="logo" :title="title" :mini="isCollapse" />
      <!-- <menu-icon
          @click.native="toggleNavbar"
          :type="isCollapse ? 'close' : 'open'"
        /> -->
      <nav class="el-header-extend">
        <el-tooltip
          class="item"
          content="通知消息"
          placement="bottom"
          v-if="false"
        >
          <Icon type="bell" size="24px" />
        </el-tooltip>

        <el-tooltip
          class="item"
          v-if="$store.state.user.is_site * 1 === 0"
          content="可视化前台"
          placement="bottom"
        >
          <span @click="$router.push('/dataV')">可视化前台</span>
        </el-tooltip>

        <el-tooltip
          class="item"
          :content="'当前帐号：' + departName"
          placement="bottom"
        >
          <span>
            <Icon type="user" size="24px" />
            <span style="line-height: 24px">
              {{ departName }}
            </span>
          </span>
        </el-tooltip>

        <el-tooltip class="item" content="退出登录" placement="bottom">
          <Icon type="logout" size="24px" @click.native="logout" />
        </el-tooltip>
      </nav>
    </el-header>
    <el-container>
      <el-aside :width="isCollapse ? '60px' : '270px'">
        <navbar :dataSource="menus" :collapse="isCollapse" />
      </el-aside>
      <el-main>
        <router-view />
      </el-main>

      <el-dialog
        title=""
        width="800px"
        class="dialog alarm-dialog"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        :center="true"
        :show-close="false"
      >
        <div class="alarm">
          <div>
            <i class="el-icon-close" @click="handleCancelAlarm"></i>
            <img src="@/assets/alarm-bg.png" />
          </div>
          <el-row class="el-dialog-row">
            <el-col
              :span="index < 4 ? 5 : 4"
              class="alarm-col"
              v-for="(item, index) in alarmList"
              :key="index"
            >
              <div @click="alarmIndex = index">
                <div class="blue alarm-icon" v-if="false">
                  <span><Icon :type="item.icon"/></span>
                </div>
                <div
                  :class="
                    alarmIndex == index ? 'blue alarm-value' : 'alarm-value'
                  "
                >
                  {{ item.value != 'undefined' ? item.value : 0 }}
                </div>
                <div
                  :class="
                    alarmIndex == index ? 'blue alarm-name' : 'alarm-name'
                  "
                  v-html="item.name"
                ></div>
              </div>
            </el-col>
          </el-row>
        </div>

        <div slot="footer" style="text-align: center">
          <el-button size="large" @click="handleCancelAlarm">
            暂时忽略
          </el-button>
          <el-button
            type="primary"
            size="large"
            style="margin-left: 25px"
            @click="handleView"
          >
            立即查看
          </el-button>
        </div>
      </el-dialog>
    </el-container>
  </el-container>
</template>
<script>
import { setStorage, getStorage } from '@/utils/storage';
import Logo from '@/components/Logo';
import Icon from '@/components/Icon';
// import MenuIcon from '@/components/Icon/Menu';
import Navbar from '@/components/Navbar';

import Resize from '@/components/Chart/resize';
// import logoImg from '@/assets/logo.png';

export default {
  components: {
    Logo,
    Icon,
    // MenuIcon,
    Navbar
  },
  data() {
    return {
      title: this.$store.state.app.title,
      logo: '',
      menus: [],
      isCollapse: false,
      dialogVisible: true,
      alarmIndex: 0,
      alarmList: [
        {
          name: '设备<br>离线预警',
          icon: 'device_offline',
          value: this.$store.getters.alarmCount,
          path: '/alr/dvc_off'
        },
        {
          name: '端口<br>离线预警',
          icon: 'tax_offline',
          value: this.$store.getters.alarmPort,
          path: '/alr/tax_off'
        },
        {
          name: '监控微处理器<br>变更预警',
          icon: 'not_tax_alarm',
          value: this.$store.getters.alarmControl,
          path: '/alr/tax_chg'
        },
        {
          name: '编码器<br>变更预警',
          icon: 'not_tax_alarm',
          value: this.$store.getters.alarmDuty,
          path: '/alr/ecdr_chg'
        },
        {
          name: '防作弊系统<br>未开启预警',
          icon: 'mch_anomalies_abno',
          value: this.$store.getters.alarmStatus,
          path: '/ncheat'
        }
      ],
      active: null
    };
  },
  computed: {
    username() {
      return this.$store.getters.username;
    },
    departName() {
      return this.$store.getters.departName;
    }
  },
  created() {
    if (
      this.$router.history.current.path === '/home' &&
      getStorage('ifIgnore') === 'true'
    ) {
      const list = this.alarmList;
      if (
        list[0].value > 0 ||
        list[1].value > 0 ||
        list[2].value > 0 ||
        list[3].value > 0 ||
        list[4].value > 0
      ) {
        this.dialogVisible = true;
      }
    } else {
      this.dialogVisible = false;
    }

    this.$store.dispatch('app/getMenus').then(res => {
      this.menus = res;
    });
  },
  mounted() {
    this.$store.dispatch('app/setGasData');
  },
  updated() {
    if (
      this.$router.history.current.path === '/home' &&
      getStorage('ifIgnore') === 'true'
    ) {
      const list = this.alarmList;
      if (
        list[0].value > 0 ||
        list[1].value > 0 ||
        list[2].value > 0 ||
        list[3].value > 0 ||
        list[4].value > 0
      ) {
        this.dialogVisible = true;
      }
    } else {
      this.dialogVisible = false;
    }

    this.active = this.$router.history.current.path;
  },
  methods: {
    toggleNavbar() {
      this.isCollapse = !this.isCollapse;
      if (window.location.href.indexOf('/home') > -1) {
        Resize.$emit('resizeEchart');
      }
    },
    logout() {
      this.$confirm('确定要退出当前帐号吗？', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$store
            .dispatch('user/logout')
            .then(() => {
              // if (res.code === 0) {
              this.$message({
                message: '成功退出！',
                type: 'warning'
              });
              this.$router.replace('/login');
              // }
            })
            .catch(err => {
              this.$message({
                message: err.msg,
                type: 'danger'
              });
            });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消当前操作！'
          });
        });
    },
    handleCancelAlarm() {
      this.dialogVisible = false;
      setStorage('ifIgnore', 'false');
    },
    handleView() {
      setStorage('ifIgnore', 'false');
      const path = this.alarmList[this.alarmIndex].path;
      this.$store.dispatch('app/setCurrentMenu', path).then(() => {
        this.$router.push({
          path: path,
          query: {
            status: '0'
          }
        });
      });
    }
  }
};
</script>
<style lang="scss">
@import '../styles.scss';

#app > .el-container {
  display: flex;
  height: inherit;
  background: #ececec;
  .el-container {
    position: relative;
    width: 100%;
    // height: calc(100% - 70px);
  }

  .el-header {
    // position: absolute;
    // top: 0;
    // right: 0;
    width: 100%;
    z-index: 999;
  }
}

.el-header,
.el-aside {
  height: calc(100% - 70px);
  background: #fff;
  box-shadow: 0 0 2px -2px rgba(#ccc, 0.4), 0 0 10px 0 rgba(#eee, 0.125);
}
.el-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $--color-primary;
  // color: #fff;
  // background: $--color-primary;
  &-extend {
    .item {
      margin: auto 12px;
      cursor: pointer;
    }
  }
  & + .el-container {
    margin: 16px 0 0;
    // position: absolute;
    // top: 70px;
    // bottom: 0;
    overflow: hidden;
    .el-main {
      padding: 0;
      overflow: auto;
    }
  }
  & + .el-main {
    margin-top: 70px;
    padding: 0;
  }
}
.el-aside {
  padding-bottom: 16px;
  height: 100%;
  box-sizing: border-box;
  transition: all 0.3s;
  border-radius: 0 12px 0 0;
  // &.dark {
  //   overflow-x: hidden;
  //   background: $dark-color;
  // }
  &::-webkit-scrollbar {
    display: none;
  }
}
.el-main {
  // margin: 30px 40px 40px 30px;
  padding-top: 0;
  box-sizing: border-box;
  background: transparent;
}

.alarm-dialog {
  min-height: 300px;

  & > div {
    background: transparent;
    box-shadow: none;
  }

  .el-dialog__header {
    display: none !important;
  }

  .el-dialog__body {
    padding: 0 !important;

    .el-dialog-row {
      background: #fff;
      margin-top: -5px;
    }
  }

  .el-dialog__footer {
    background: #fff;
    border-radius: 0 0 20px 20px;
  }

  .alarm-col {
    text-align: center;
    margin: 50px 0 40px;
    cursor: pointer;

    .alarm-icon {
      span {
        display: inline-block;
        background: #eaf0fb;
        padding: 10px;
        border-radius: 5px;
      }

      i {
        font-size: 24px;
      }
    }

    .alarm-value {
      font-size: 24px;
      line-height: 50px;
    }
  }
  .alarm-name {
    color: #666;
    font-size: 18px;
  }

  .blue {
    color: #01adbe;
  }

  .el-icon-close {
    position: absolute;
    right: 20px;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    top: 20px;
  }
}
</style>
