<template>
  <div>
    <el-table
      ref="table"
      v-loading="loading"
      :data="dataSource"
      :height="height"
      :size="size"
      :highlight-current-row="highlight"
      :header-cell-style="
        thStyle || {
          color: '#333',
          'white-space': 'nowrap',
          'background-color': 'rgba(243,244,244,1)'
        }
      "
      :row-class-name="tableRowClassName"
    >
      <template v-for="column in columns">
        <el-table-column
          v-if="!column.scopedSlots"
          :prop="column.key"
          :fixed="column.fixed"
          :label="column.title"
          :width="column.width"
          :align="column.align"
          :key="column.key"
          :show-overflow-tooltip="true"
          :min-width="column.minWidth"
          :sortable="column.sort"
          :sort-method="(a, b) => handleSortMethod(a, b, column.key)"
        />
        <el-table-column
          v-else
          :prop="column.key"
          :fixed="column.fixed"
          :label="column.title"
          :width="column.width"
          :align="column.align"
          :key="column.key"
          :min-width="column.minWidth"
          :sortable="column.sort"
          :sort-method="(a, b) => handleSortMethod(a, b, column.key)"
        >
          <template slot-scope="scope">
            <slot
              :name="column.scopedSlots.customRender"
              v-bind:scope="scope"
            />
          </template>
        </el-table-column>
      </template>
    </el-table>
    <div class="table-pagination" v-if="pagination !== 'none'">
      <el-pagination
        background
        layout="prev, pager, next, total, jumper"
        :current-page="pagination.current"
        :page-size="pagination.pageSize"
        :total="pagination.total"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
    <slot name="dialog" />
  </div>
</template>

<script>
// import Expand from './expand';
import moment from 'moment';
export default {
  components: {
    // ExpandComponent: Expand
  },
  props: {
    columns: {
      type: Array,
      default() {
        return [];
      }
    },
    dataSource: {
      type: Array,
      default() {
        return [];
      }
    },
    size: String,
    height: [String, Number],
    highlight: {
      default: false
    },
    thStyle: Object,
    pagination: {
      type: [Object, String],
      default() {
        return {
          total: 0,
          current: 1,
          pageSize: 10
        };
      }
    },
    tableStatus: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  methods: {
    handleCurrentChange(val) {
      this.$emit('currentChange', val);
    },
    compare(last_time) {
      return moment(last_time).isBefore(moment());
    },
    handleSortMethod(a, b, key) {
      //表格排序规则
      return a[key] - b[key];
    },
    tableRowClassName({ row }) {
      // console.log(this.tableStatus, row[this.tableStatus]);
      if (row[this.tableStatus] === '') {
        return 'warning-row';
      } else {
        return '';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.table-pagination {
  margin: 16px auto 32px;
  text-align: center;
}

.text {
  &-success {
    color: #01adbe;
  }
  &-danger {
    color: #f56c6c;
  }
  &-info {
    color: #999;
  }
}
</style>
<style>
.warning-row {
  color: #f56c6c;
}
</style>
