import { decrypto, encrypto } from './crypto_aes';

export function getStorage(key) {
  const json = localStorage.getItem(key);
  if (json) {
    try {
      return decrypto(json);
    } catch {
      // 防止解析失败
    }
  }
  return null;
  // return decrypto(localStorage.getItem(key));
}

export function setStorage(key, value) {
  const v = encrypto(value);
  localStorage.setItem(key, v);
}

export function removeStorage(key) {
  localStorage.removeItem(key);
}

export function clearStorage() {
  localStorage.clear();
}
