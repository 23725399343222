/*
 * @Author       : Banmao
 * @Date         : 2022-07-28 13:51:50
 * @LastEditTime : 2022-07-28 14:35:14
 * @LastEditors  : maculosa
 * @FilePath     : /p202/src/store/modules/station.js
 * @Description  :
 */
const state = {
  stationFormVisible: false,
  stationForm: {}
};

const mutations = {};

const actions = {
  toggleVisible({ state }, visible) {
    state.stationFormVisible = visible;
  },
  getStationData({ state }, payload) {
    state.stationData = payload;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
